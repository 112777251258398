import React from 'react';
import { Link } from "gatsby"
import parse from 'html-react-parser';
import shortid from  "shortid";
import FadeUpOnScroll from '../../../components/FadeUp';

const AboutSection2 = (props) => {

    return (
        <section className="section-2">
            <div className="container">
                <div className='about-detail'>
                    <div className="row">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">
                            <div className="title-block">
                                <h2 className="h2 section-title">{props.mainTitle}</h2>
                                <div className="count-items">
                                    {props.list?.map((object, i) => {
                                        return (
                                            <div className="item" key={shortid.generate()}>
                                                <h2 className="h1 value">{parse(object.value)}</h2>
                                                <div className="label"><p className="small">{parse(object.label)}</p></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <p className="section-description">{props.mainDescription}</p>
                            <div className="awards-list">
                                {props.awardsList?.map((object, i) => {
                                    return (
                                        <div className="item" key={shortid.generate()}>
                                            <img
                                                src={object.image?.sourceUrl}
                                                width={object.image?.width}
                                                alt={object.image?.altText}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection2